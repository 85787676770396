<template>
  <!-- 这里封装顶部导航栏的全部代码 -->
  <div class="wrapper">
    <el-button style="height: 16px !important;" class="all-dialog el-button--text" icon="el-icon-arrow-down"
      @click="drawer = true">
      点击展开所有对话
    </el-button>

    <div class="total">
      <el-drawer title="以下是您的全部对话内容：" :visible.sync="drawer" :modal="false" direction="ttb" size='65%'
        style="margin: 0 16%;">
        <!-- :before-close="handleClose"> -->
        <template #default>
          <div class="content" style="margin-left: 13%; margin-right: 10%;">
            <div v-for="page in pages" :key="page.pageid" class="drawer-menubar-item"
              :class="{ 'drawer-menubar-item-choose': selectedid == page.pageid }">
              <!-- <el-button type="button" @click="deleteOrNot = true;deleteid = page.pageid;" class="el-button el-button--text" style="color: red;margin-left: 10px;">删除对话</el-button> -->

              <router-link v-if="isHomePage" :to="/chat/ + page.pageid" class="all-title">
                <!-- :class="{ 'all-title-choose':selectedid == page.pageid }" -->
                <!-- @click.native="handleSelecte(page.pageid)" -->
                {{ page.topic }}
              </router-link>
              <router-link v-else :to="/chat/ + page.pageid" class="all-title" onclick="location.reload()">
                <!-- :class="{ 'all-title-choose':selectedid == page.pageid }" -->
                <!-- @click.native="handleSelecte(page.pageid)" -->
                {{ page.topic }}
              </router-link>
              <el-button type="button" @click="deleteOrNot = true; deleteid = page.pageid;"
                class="el-button el-button--text" style="color: red;
              margin-left: 10px;
              margin-right: 10px">
                <i class="el-icon-delete" style="font-size: 15px"></i>
              </el-button>
            </div>
          </div>
        </template>
      </el-drawer>
      <!-- 首页 -->
      <div class="new-dialog">
        <el-button icon="el-icon-s-home" style="height: 47px;
          width: 115px;
          margin-left: 10%;" v-if="isHomePage" type="info" plain @click="cue">首页</el-button>
        <el-button icon="el-icon-s-home" v-else type="info" plain style="height: 47px;
          margin-left: 10%;" @click="backHome">回到首页</el-button>
      </div>
      <!-- 切换对话用的滚轮 -->
      <div class="menubar-scrollbar" ref="menubarScrollbar">
        <!-- 这里保证导航栏的横向排版 -->
        <div class="menubar">

          <div v-for="page in pages" :key="page.pageid" class="menubar-item"
            :class="{ 'menubar-item-choose': selectedid == page.pageid }">
            <router-link v-if="isHomePage" class="all-title" :to="/chat/ + page.pageid"
              :class="{ 'menubar-item-choose': selectedid == page.pageid }">
              <!-- @click.native="handleSelecte(page.pageid)" -->
              {{ page.topic }}
            </router-link>
            <router-link v-else :to="/chat/ + page.pageid" class="all-title"
              :class="{ 'all-title-choose': selectedid == page.pageid }" onclick="location.reload()">
              <!-- @click.native="handleSelecte(page.pageid)" -->
              {{ page.topic }}
            </router-link>
            <el-button type="button" @click="deleteOrNot = true; deleteid = page.pageid;"
              class="el-button el-button--text" style="color: red;
            margin-left: 10px;
            margin-right: 10px">
              <i class="el-icon-delete" style="font-size: 15px"></i>
            </el-button>
          </div>

          <el-dialog title="确认删除此对话？" :visible.sync="deleteOrNot" :append-to-body="true" width="30%">
            <span>即将删除</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="deleteOrNot = false">取 消</el-button>
              <el-button type="danger" @click="deleteDialog()">确认删除</el-button>
            </span>
          </el-dialog>

        </div>
      </div>
      <!-- 新建对话 -->
      <div class="new-dialog">
        <el-button style="height: 47px;
        margin-right: 10%;" icon="el-icon-s-comment" type="info" plain @click="newDialog">新建对话</el-button>

        <el-dialog class="big-dialog" :append-to-body=true title="请输入对话的话题" :visible.sync="inputTopic" width="30%">
          <div class="modal-body">
            <input type="text" class="title" id="title" v-model="topic" placeholder=" 例如：青岛美食/景点"
              autofocus="autofocus">
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="inputTopic = false">取 消</el-button>
            <el-button type="primary" @click="createPage">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs';
export default {
  data() {
    return {
      pages: [],
      pageid: -1,
      deleteOrNot: false,
      deleteid: -1,
      inputTopic: false,
      topic: '',
      isHomePage: false,
      drawer: false,
      selectedid: '-1',
    }
  },

  methods: {
    getPages() {
      console.log(11111111)
      axios({
        method: 'get',
        url: '/api/page',
        headers: { 'token': sessionStorage.getItem('jwt') }
      }).then(response => {
        // 登录检测
        // if ('true'.localeCompare(sessionStorage.getItem('notLogin')) === 0) return
        if ('notLogin'.localeCompare(response.data.msg) === 0) {
          this.$router.push({
            path: '/'
          })
          sessionStorage.setItem('notLogin', 'true')
          return
        }
        // 按创建时间先后来反序排列
        this.pages = response.data.data.sort((a, b) => {
          // 转换成时间戳进行比较
          const timeA = new Date(a.createTime).getTime();
          const timeB = new Date(b.createTime).getTime();

          // 如果 timeB 小于 timeA，则应该将 b 放在前面，返回 -1
          // 如果 timeB 大于 timeA，则将 a 放在前面，返回 1
          // 如果它们相等，则不改变顺序，返回 0
          return timeB - timeA;
        });
      })
    },
    deleteDialog() {
      this.deleteOrNot = false
      axios({
        method: 'delete',
        url: '/api/page',
        headers: { 'token': sessionStorage.getItem('jwt') },
        params: {
          pageid: this.deleteid
        }
      }).then(response => {
        console.log(response.data)
        if (this.deleteid == this.pageid) {
          this.$router.push({
            path: '/home'
          })
        } else
          this.getPages()
      })
    },
    createPage() {
      if (this.topic === '') {
        this.$message({
          type: 'error',
          message: '标题不能为空！！'
        })
        return
      }
      axios({
        method: 'post',
        url: '/api/page',
        headers: { 'token': sessionStorage.getItem('jwt') },
        data: qs.stringify({
          'topic': this.topic
        })
        // params:{
        //   topic:this.topic
        // }
      }).then(response => {
        this.$router.push({
          path: '/chat/' + response.data.data
        })
        if (!this.isHomePage) {
          this.$nextTick(location.reload())
        }
      })
    },
    backHome() {
      this.$router.push({
        path: '/home'
      })
    },
    addScrollEventListener() {
      const menuBarScrollbar = this.$refs.menubarScrollbar; // 使用ref引用你的滚动条元素
      if (menuBarScrollbar) {
        menuBarScrollbar.addEventListener('wheel', this.handleScroll);
      }
    },
    removeScrollEventListener() {
      const menuBarScrollbar = this.$refs.menubarScrollbar; // 使用ref引用你的滚动条元素
      if (menuBarScrollbar) {
        menuBarScrollbar.removeEventListener('wheel', this.handleScroll);
      }
    },
    handleScroll(event) {
      const menuBarScrollbar = this.$refs.menubarScrollbar;
      if (menuBarScrollbar) {
        // deltaY > 0 表示向下滚动，deltaY < 0 表示向上滚动
        if (event.deltaY > 0) {
          menuBarScrollbar.scrollLeft += 99; // 向右滚动
        } else {
          menuBarScrollbar.scrollLeft -= 99; // 向左滚动
        }
        // 防止页面滚动
        event.preventDefault();
      }
    },
    // handleSelecte (id) {
    //   sessionStorage.setItem('id',id)
    //   console.log(id == this.selectedid)
    // },
    newDialog() {
      this.inputTopic = true
    },
    cue() {
      this.$message('您已经在首页了哦(^▽^ )');
    },
  },

  mounted() {
    if (this.$route.path === "/home") {
      this.isHomePage = true;
    } else {
      // this.selectedid = sessionStorage.getItem('id')
      this.selectedid = this.$route.params.pageid
      this.pageid = this.selectedid
    }
    this.getPages()
    this.addScrollEventListener();
  },

  beforeDestroy() {
    this.removeScrollEventListener();
  },
}
</script>

<style scoped>
.wrapper {
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  z-index: 2;
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: #82c7fc; */
}

.all-dialog {
  width: 100%;
  height: 18px !important;
  position: inherit;
  /* 绝对定位 */
  background-color: white !important;
  top: 0;
  /* 距顶部 0px，确保它在顶部 */
  left: 50px;
  /* 水平居中的技巧之一 */
  /* left: 50%; 水平居中的技巧之一 */
  z-index: 0;
  /* transform: translateX(-50%); 向左偏移按钮宽度的一半以确保准确居中 */
  padding: 0 !important;
}

.menubar-scrollbar {
  overflow: auto;
  overflow-x: auto;
  /* 显示水平滚动条 */
  white-space: nowrap;
  /* 水平排列元素 */
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  /* border:solid #82c7fc 4px; */
  /* box-shadow: 0 0 2px rgba(19, 176, 255, 0.448), 0 0 6px rgba(19, 176, 255, 0.618); */
  border-radius: 1ch;
  background-color: white
}

.menubar {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.drawer-menubar-item {
  flex: 0 0 calc(20% - 20px);
  /* flex规则设置为不伸缩、不收缩及固定宽度为20%减去margin下的宽度 */
  margin: 10px;
  /* 保持10px的间距 */
  display: flex;
  /* 每个项目内部为flex布局 */
  justify-content: center;
  /* 子项目水平居中 */
  align-items: center;
  /* 子项目垂直居中 */
  border: 2px solid #f3f3f3 !important;
  padding: 4px 6px;
  border-radius: 10px !important;
}

.menubar-item {
  display: flex;
  margin: 0px 10px;
  border: 2px solid #f3f3f3 !important;
  padding: 4px 6px;
  border-radius: 10px !important;
}

.menubar-item-choose,
.drawer-menubar-item-choose {
  background-color: #f3f3f3;
}

a {
  text-decoration: none !important;
  color: #333 !important;
  font-size: 12px;
  line-height: 40px;
  /* 这里必须设置成两倍删除按钮的margin */
  display: block;
  text-align: center;
  margin-left: 10px;
}

.new-dialog {
  margin: 10px 50px;
  height: 48px !important;
}

.modal-body input {
  font-size: 16px;
  padding-left: 4px;
}

.big-dialog /deep/ .el-dialog__body {
  padding: 15px 20px;
}

.dialog-footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

.title {
  width: 80%;
  margin-left: 10%;
  height: 40px;
  border-radius: 6px;
  border-color: #000000;
  transition-delay: 0.1s;
}

.all-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 76px;
  font-size: 14px;
  font-weight: 500;
}

.all-title-choose {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 76px;
  background-color: #f3f3f3;
  /* border: 3px solid rgb(178, 215, 232) !important; */
  border-radius: 8px;
}

.el-carousel__container {
  background: #f3f3f3;
}

.el-button--info {
  background-color: #efefef;
  border-color: #efefef;
  color: #909090;
  font-weight: bold;
  opacity: 0.8;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #eaeaea;
  border-radius: 5px;
  cursor: pointer;
}

* {
  scrollbar-base-color: green;
  scrollbar-track-color: red;
  scrollbar-arrow-color: blue;
}

.el-dialog__wrapper /deep/ .el-dialog {
  border-radius: 12px;
  height: 200px;
}
</style>