<template>
  <div>
    <div class="dialogg">
      <el-dialog title="关于我们" :visible.sync="showAbout" width="30%" center>
        <div class="change-dialog">
          <div>
            程序版本：2.0.1
          </div>
          <hr class="hr">
          <div>
            给我们打个分吧~
            <el-rate v-model="value2" :colors="colors">
            </el-rate>
          </div>
          <hr>
          <div>
            意见反馈
          </div>
          <div>
            请将您的意见和建议发送到
          </div>
          <div>
            邮箱12345678@qq.com
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAbout = false">关 闭</el-button>
          <el-button type="primary" @click="showAbout = false">关 闭</el-button>
        </span>
      </el-dialog>
      <el-dialog title="设置" class="big-dialog" :visible.sync="showDetail" width="40%">
        <div>
          <hr class="hr">
          <el-container>
            <el-aside width="25%" style="height: 300px;">
              <div>
                <el-menu default-active="1" class="el-menu-vertical-demo" @select="handleSelect">
                  <el-menu-item index="1" style="margin-top: 10px;">
                    <i class="el-icon-setting"></i>
                    <span slot="title">数据管理</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <i class="el-icon-document"></i>
                    <span slot="title">用户资料</span>
                  </el-menu-item>
                  <el-menu-item index="3">
                    <i class="el-icon-unlock"></i>
                    <span slot="title">安全</span>
                  </el-menu-item>
                  <el-menu-item index="4">
                    <i class="el-icon-link"></i>
                    <span slot="title">关于我们</span>
                  </el-menu-item>
                  <el-menu-item index="5">
                    <i class="el-icon-help"></i>
                    <span slot="title">帮助与支持</span>
                  </el-menu-item>
                  <el-menu-item index="6">
                    <i class="el-icon-s-operation"></i>
                    <span slot="title">语言设置</span>
                  </el-menu-item>
                </el-menu>
              </div>
            </el-aside>

            <el-main v-if="currentIndex == 1" class="main">
              <div>
                <div class="universal">
                  <div class="recommend">
                    清除缓存
                  </div>
                  <el-button round size="medium" @click="openDeleteAll">清除</el-button>
                </div>
                <hr class="hr">
                <div class="universal">
                  <div class="recommend">
                    导出对话
                  </div>
                  <el-button round size="medium" class="special">导出</el-button>
                </div>
              </div>
            </el-main>
            <el-main v-else-if="currentIndex == 2">
              <div class="new-info">
                <div class="upper">
                  <div class="send-my-head">
                    <el-upload class="avatar-uploader" action='/api/upload' name="imageFile"
                      :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                      <img v-if="imageUrl" :src="imageUrl" class="my-old-head">
                      <i v-else class="el-icon-plus avatar-uploader-icon my-old-head"></i>
                    </el-upload>
                  </div>
                  <div class="send-my-name">
                    <label for="newUsername">用户名：</label>
                    <el-input v-model="username" id="newUsername" placeholder="请输入新用户名称"></el-input>
                  </div>
                  <div class="send-my-password">
                    <label for="newPassword">密码：</label>
                    <el-input id="newPassword" placeholder="请输入新用户密码" v-model="password" show-password></el-input>
                  </div>

                </div>
                <div class="bottom">
                  <el-button round @click="handleAgain">取消</el-button>
                  <el-button round type="primary" @click="handleChangeUserInfo">确认</el-button>
                </div>
              </div>
            </el-main>
            <el-main v-else-if="currentIndex == 3">
              <div>
                <div class="universal">
                  <div class="recommend">
                    退出登录
                  </div>
                  <el-button round size="medium" @click="signOut">退出</el-button>
                </div>
                <hr class="hr">
                <div class="universal">
                  <div class="recommend">
                    注销账号
                  </div>
                  <el-button round type="danger" size="medium" class="special" @click="openLogOut">注销</el-button>
                </div>
              </div>
            </el-main>
            <el-main v-else-if="currentIndex == 4">
              <div>
                <div class="universal">
                  <div class="recommend">
                    分享网站
                  </div>
                  <el-button round size="medium" @click="copy">分享</el-button>
                </div>
                <hr class="hr">
                <div class="universal">
                  <div class="recommend">
                    评价一下
                  </div>
                  <el-button round size="medium" @click="showAbout = true">评价</el-button>
                </div>
                <hr class="hr">
                <div class="universal">
                  <div class="recommend">
                    微信小程序端
                  </div>
                  <el-button round size="medium" type="text" disabled>敬请期待</el-button>
                </div>
              </div>
            </el-main>
          </el-container>
        </div>
      </el-dialog>

    </div>

    <div class="info">
      <img class="my-head" :src="imageUrl" @click="showDetail = true">
    </div>
    <top-navigation ref="top"></top-navigation>

    <div>
      <el-carousel style="z-index: 0; margin-top: 8px;" :interval="4000" type="card" :height=heightall>
        <el-carousel-item v-for="item in photos" :key="item.id" style="width: 1000px;">
          <a :href="item.to">
            <!-- 可以取巧把图片标题直接嵌入到图片中展示更加方便 -->
            <img :src="item.src" class="photos" :height=heightall>
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="bottom-navigation">
      <div class="big">
        <div class="typing-me">
          <div>
            <img class="head" src="@/assets/AI.png" alt="">
          </div>
          <div class="word">
            <vue-typed-js :strings="asking" :shuffle="true" :showCursor="false" :backSpeed="30" :backDelay="4000"
              :startDelay="1000">
              <div class="typing"></div>
            </vue-typed-js>
          </div>
        </div>
        <div class="new-dialog">
          <el-button type="info" plain round icon="el-icon-circle-plus" @click="handleNew">开始新对话
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import axios from 'axios';
import qs from 'qs'

export default {
  data() {
    return {
      newbtn: false,
      heightall: '550px',
      imageUrl: '',
      username: '',
      password: '',
      showDetail: false,
      showAbout: false,
      currentIndex: 1,
      asking: [
        '：您好，请问今天我能为您做什么？',
        '：我能为您制定一份青岛旅游计划。',
        '：请向我提问，我会尽可能解答您的疑惑。',
        '：您好，有什么问题想要问我吗？',
        '：您好，有什么我可以帮您解决的问题吗？',
        '：我可以为您安排一次青岛海边度假旅行。',
        '：您想要了解一些青岛的相关信息吗?',
        '：您好，我可以成为您的青岛导游。',
        // ''
      ],
      photos: [
        {
          // 崂山风景区
          "id": 1,
          "name": "图片1",
          "src": require("@/assets/1.jpg"),
          "to": "https://baike.baidu.com/item/%E5%B4%82%E5%B1%B1%E9%A3%8E%E6%99%AF%E5%90%8D%E8%83%9C%E5%8C%BA/3430648"
        },
        {
          // 八大关
          "id": 2,
          "name": "图片2",
          "src": require("@/assets/2.jpg"),
          "to": "https://baike.baidu.com/item/%E5%85%AB%E5%A4%A7%E5%85%B3/1170054"
        },
        {
          // 栈桥
          "id": 3,
          "name": "图片3",
          "src": require("@/assets/3.jpg"),
          "to": "https://baike.baidu.com/item/%E6%A0%88%E6%A1%A5%E5%8F%8A%E5%9B%9E%E6%BE%9C%E9%98%81/58277053"
        },
        {
          // 琅玡台
          "id": 4,
          "name": "图片4",
          "src": require("@/assets/4.jpg"),
          "to": "https://baike.baidu.com/item/%E7%90%85%E7%90%8A%E5%8F%B0/792107"
        },
        {
          // 五四广场
          "id": 5,
          "name": "图片5",
          "src": require("@/assets/5.jpg"),
          "to": "https://baike.baidu.com/item/%E4%BA%94%E5%9B%9B%E5%B9%BF%E5%9C%BA/1111704"
        },
        {
          // 青岛天主教堂
          "id": 6,
          "name": "图片6",
          "src": require("@/assets/6.jpg"),
          "to": "https://baike.baidu.com/item/%E9%9D%92%E5%B2%9B%E5%A4%A9%E4%B8%BB%E6%95%99%E5%A0%82/3148073"
        },
      ],

    }
  },

  components: {
    TopNavigation,
  },

  methods: {
    handleNew() {
      this.$refs.top.newDialog()
    },
    getRatio() {
      var ratio = 0;
      var screen = window.screen;
      var ua = navigator.userAgent.toLowerCase();

      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      }
      else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      }
      else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
      }
      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      return ratio;
    },
    handleSelect(index) {
      this.currentIndex = index
    },
    openDeleteAll() {
      this.$confirm('此操作将清除所有对话记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          method: 'delete',
          url: '/api/deleteall',
          headers: { 'token': sessionStorage.getItem('jwt') }
        })
        this.$message({
          type: 'success',
          message: '对话记录清除成功！即将刷新页面',
        });
        setTimeout(() => {
          location.reload()
        }, 1000);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    signOut() {
      this.$confirm('即将退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '下次再见啦~'
        });
        sessionStorage.removeItem('jwt')
        this.$router.push({
          path: '/'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    openLogOut() {
      this.$confirm('此操作将永久注销该账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '注销成功！欢迎下次使用'
        });
        this.logout()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消注销'
        });
      });
    },
    logout() {
      axios({
        method: "delete",
        url: '/api/delete',
        headers: { 'token': sessionStorage.getItem('jwt') }
      })
        .then(() => {
          sessionStorage.removeItem('jwt')
          this.$router.push({
            path: '/'
          })
        })
    },
    copy() {
      navigator.clipboard.writeText(window.location.href)
      this.$message({
        message: '已将本网站地址拷贝到剪切板！',
        type: 'success'
      });
    },

    // 修改个人信息
    handleAvatarSuccess(res) {
      this.imageUrl = res.data
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleAgain() {
      axios({
        method: 'get',
        headers: { 'token': sessionStorage.getItem('jwt') },
        url: '/api/all',
      }).then(response => {
        this.username = response.data.data.username;
        this.password = response.data.data.password;
        this.imageUrl = response.data.data.imageUrl;
      });
      this.showDetail = false
    },
    handleChangeUserInfo() {
      axios({
        method: "post",
        url: '/api/update',
        headers: { 'token': sessionStorage.getItem('jwt') },
        data: qs.stringify({
          'username': this.username,
          'password': this.password,
          'imageUrl': this.imageUrl,
        })
      })
        .then(response => {
          // 在这里更新前端显示的用户名和密码
          this.username = response.data.username;
          this.password = response.data.password;
          this.imageUrl = response.data.imageUrl;
          // 更新jwt令牌！！！！！！！
          sessionStorage.setItem('jwt', response.data.data)
          location.reload()
        })
        .catch(error => {
          console.error('更新失败', error.response);
        });
    },
  },

  mounted() {
    // sessionStorage.setItem('notLogin', false)
    if (sessionStorage.getItem('reloadFlag') == '1') {
      sessionStorage.removeItem('reloadFlag')
      location.reload()
    }
    var h1 = 550
    var h2 = (h1 / 864) * window.screen.height + 'px'
    this.heightall = h2

    axios({
      methods: 'get',
      headers: { 'token': sessionStorage.getItem('jwt') },
      url: '/api/all',
    }).then(response => {
      if ('notLogin'.localeCompare(response.data.msg) === 0) {
        sessionStorage.setItem('notLogin', 'true')
        return
      }
      this.username = response.data.data.username
      this.password = response.data.data.password
      this.imageUrl = response.data.data.imageUrl
    })
  }
}
</script>

<style scoped>
.hr {
  background-color: #d0d0d0;
  height: 1px;
  border: none;
}

.info {
  position: fixed;
  display: flex;
  left: 40px;
  bottom: 4%;
  height: 82px;
  width: 210px;
  padding-left: 6px;
  padding-top: 4px;
  /* border: rgb(194, 194, 194) 2px solid; */
  border-radius: 10px
}

.my-head {
  position: fixed;
  height: 70px;
  width: 70px;
  cursor: pointer;
  border-radius: 35px;
  margin-right: 20px;
  margin-left: 2px;
  margin-top: 4px;
  border: rgb(209, 209, 209) 1px solid;
}

.el-dialog__wrapper /deep/ .el-dialog {
  border-radius: 12px;
  height: 400px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.photos {
  width: 734px;
  /* height: 550px; */
}

.bottom-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.big {
  display: flex;
  margin-left: 200px
}

.new-dialog {
  width: 200px;
  text-align: center;
  margin: 0;
  margin-top: 11px;
}

.new-dialog .el-button--info {
  background-color: #efefef;
  border-color: #efefef;
  color: #909090;
  font-weight: bold;
  opacity: 0.8;
}

.typing-me {
  display: flex;
  /* justify-content: space-around; */
  /* background-color: rgba(164, 213, 255, 0.701); */
  border: #475669 2px solid;
  padding: 16px 14px 12px 14px;
  border-radius: 100px;
  width: 740px;
  margin-left: 0px;
}

.typing {
  font-size: 22px;
  font-weight: bold;
  margin-left: 10px;
}

.head {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.el-menu-item {
  border-radius: 12px;
  height: 46px;
  line-height: 46px;
}

.el-menu-item.is-active {
  color: #000000;
  font-weight: 600;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #d0d0d0;
  border-radius: 12px;
}

.el-main {
  padding: 0 20px;
}

.universal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  padding: 12px 0;
}

.recommend {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-button:hover,
.el-button:focus {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #000000;
}

.el-button--danger:hover,
.el-button--danger:focus {
  background-color: #ff8989;
  border-color: #ff8989;
  color: #ffffff;
}

.el-button--medium.is-round {
  padding: 12px 15px;
}

.change-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.change-dialog-items-middle {
  margin: 20px 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.my-old-head {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-top: 12px;
}

.new-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.send-my-name .el-input,
.send-my-password .el-input {
  margin: 2px 0 10px 0;
}

.send-my-name /deep/ input.el-input__inner,
.send-my-password /deep/ input.el-input__inner {
  border-radius: 12px
}

.send-my-name /deep/ input.el-input__inner:hover,
.send-my-name /deep/ input.el-input__inner:focus,
.send-my-password /deep/ input.el-input__inner:hover,
.send-my-password /deep/ input.el-input__inner:focus {
  border-radius: 12px;
  border-color: #666666;
}

.big-dialog /deep/ .el-dialog__body {
  padding: 15px 20px;
}

.main.el-button.is-round {}
</style>