import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chat/:pageid',
    name: 'chat',
    component:() => import("../views/ChatPage.vue")
  },
  {
    path: '/admin',
    name: 'administor',
    component:() => import("../views/AdminLog.vue")
  },
  {
    path: '/upload',
    name: 'upload',
    component:() => import("../views/AdminPage.vue")
  },
  {
    path: '/register',
    name: 'add',
    component:() => import("../views/RegisterAdd.vue")
  },
  {
    path: '/',
    name: 'register',
    component:() => import("../views/RegisterLog.vue")
  },
]

const router = new VueRouter({
  routes,
  // mode: 'history'
})

export default router
